<template>
  <div>
    <PageHeader :title="$t('routes.VIEW_CREDIT_ALL')" />
    <b-card>
      <h6>
        {{ `${$t('fields.result')} (${transactions.length === selectedLimit ? transactions.length + "+" : transactions.length})` }}
      </h6>
      <b-row>
        <b-col cols="3">
          <b-input-group>
            <b-form-input
              v-model="search"
              :placeholder="`${$t('fields.search')} (เบอร์ / ชื่อ / เลขบัญชี)`"
              type="search"
              @keydown.enter="fetchData"
            ></b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="3">
          <MasterSelectInput
            :value="selectedMaster"
            hide-label
            @update="onMasterIdChange"
          />
        </b-col>
        <b-col cols="3">
          <AgentSelectInput
            :master-id="selectedMaster"
            :value="selectedAgent"
            hide-label
            @update="onAgentIdChange"
          />
        </b-col>
        <b-col cols="3">
          <UserAgentSelectInput
            :agent-id="selectedAgent"
            :value="selectedUserAgent"
            hide-label
            @update="onUserAgentIdChange"
          />
        </b-col>
      </b-row>
      <b-row align-v="center" class="my-3">
        <b-col md="3">
          <b-select v-model="selectedType">
            <b-form-select-option value="">เลือกประเภท</b-form-select-option>
            <b-form-select-option
              v-for="(type, typeI) in typeOptions"
              :key="typeI"
              :value="type.value"
              >{{ type.name }}
            </b-form-select-option>
          </b-select>
          <b-select v-model="gameId" :options="gameOptions"></b-select>
        </b-col>
        <b-col lg="5" md="7" offset-lg="2" offset-xl="3" xl="4">
          <b-input-group prepend="วันที่">
            <b-form-datepicker
              v-model="selectedDate"
              reset-button
            ></b-form-datepicker>
          </b-input-group>
        </b-col>
        <b-col md="2">
          <b-button block @click="fetchData">
            <i class="uil uil-search"></i>
            ค้นหา
          </b-button>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-table
        :busy="isLoading"
        :fields="fields"
        :items="transactions"
        responsive
        show-empty
      >
        <template #cell(createdAt)="data">
          {{ data.value | datetime }}
        </template>
        <template #cell(username)="data">
          {{ data.value || '-' }}
          <small class="text-muted">{{ data.item.codeName }}</small>
        </template>
        <template #cell(userAgentName)="data">
          {{ data.value || '-' }}
        </template>
        <template #cell(amount)="data">
          <span :class="`text-${displayCreditTypeColor(data.item.type)}`">
            <span v-if="displayCreditTypeColor(data.item.type) === 'success'">
              +
            </span>
            <span
              v-else-if="displayCreditTypeColor(data.item.type) === 'danger'"
            >
              -
            </span>
            {{ data.value | currency }}
          </span>
        </template>
        <template #cell(beforeAmount)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(afterAmount)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(type)="data">
          <b-badge :variant="displayCreditTypeColor(data.value)">
            {{ displayCreditType(data.value) }}
          </b-badge>
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #empty="">
          <p class="text-center text-muted">{{
            $t('messages.nothing_here')
          }}</p>
        </template>
      </b-table>
      <!-- pagination -->
      <b-row align-v="center" class="mb-3">
        <b-col cols="4">
          <LimitSelect v-model="selectedLimit" />
        </b-col>
        <b-col>
          <SimplePagination
            :has-more-page="pagination.itemCount >= selectedLimit"
            :per-page="limit"
            @update="(val) => (currentPage = val)"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import dayjs from 'dayjs';

export default {
  page: {
    title: 'รายงานเครดิตผู้เล่น',
  },
  components: {
    SimplePagination: () => import('@components/commons/simple-pagination'),
    MasterSelectInput: () => import('@components/master/master-select-input'),
    AgentSelectInput: () =>
      import('@components/agents/agents-select-input.vue'),
    UserAgentSelectInput: () =>
      import('@components/user-agent/user-agent-select-input.vue'),
  },
  data() {
    return {
      isLoading: true,
      gameId: null,
      search: '',
      selectedMaster: '',
      selectedAgent: '',
      selectedUserAgent: '',
      selectedDate: dayjs().format('YYYY-MM-DD'),
      selectedType: '',
      typeOptions: [
        { name: 'ทั้งหมด', value: 'ALL' },
        { name: 'เติมตรง-ถอนตรง', value: 'DIRECT' },
        { name: 'โอนเงิน', value: 'TRANSFER' },
        { name: 'เดิมพัน', value: 'BETTING' },
      ],
      currentPage: 1,
      selectedLimit: 20,

      fields: [
        {
          key: 'createdAt',
          label: this.$t('fields.issued_date'),
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'username',
          label: `${this.$t('fields.user_phone_number')}`,
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'type',
          label: this.$t('credits.type'),
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'beforeAmount',
          label: this.$t('credits.before_amount'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'amount',
          label: this.$t('credits.credit_amount'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'afterAmount',
          label: this.$t('credits.remain_credits'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'userAgentName',
          label: 'ผู้กระทำ',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'detail',
          label: `${this.$t('fields.remark')}`,
          thStyle: {
            minWidth: '250px',
          },
        },
      ],
      gameOptions: [],
      masterCredits: {
        data: [],
        total: 0,
      },
      exporting: false
    }
  },
  computed: {
    ...mapGetters(['providerGames']),
    ...mapGetters(['isOwner', 'playersCreditHistory']),
    transactions() {
      return this.playersCreditHistory.items || []
    },
    pagination() {
      return this.playersCreditHistory.meta
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
    querySearch() {
      return this.$route.query.search || ''
    }
  },
  watch: {
    currentPage(val) {
      if (val) {
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    selectedLimit(val) {
      if(val) {
        this.currentPage > 1 ? this.currentPage = 1 : this.fetchData()
      }
    },
  },
  async created() {
    this.isLoading = true
    await this.fetchGames()
    if (this.querySearch) {
      this.search = this.querySearch
      await this.fetchData()
    }
    this.isLoading = false
    this.fetchDataAssistants()
  },
  methods: {
    ...mapActions([
      'fetchPlayersCreditHistory',
      'fetchProviderGames',
      'fetchAssistantsForInput',
    ]),
    async fetchData() {
      this.isLoading = true
      await this.fetchPlayersCreditHistory({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: this.search,
        agentId: this.selectedAgent,
        userAgentId: this.selectedUserAgent,
        gameId: this.gameId,
        masterId: this.selectedMaster,
        type: this.selectedType,
        date: this.selectedDate || '',
      })
      this.isLoading = false
    },
    async fetchGames() {
      await this.fetchProviderGames()
      this.gameOptions = this.providerGames.map((game) => ({
        text: game.name,
        value: game.gameId,
      }))
      this.gameOptions.unshift({
        text: 'เลือกเกม',
        value: null,
      })
    },
    fetchDataAssistants() {
      this.fetchAssistantsForInput()
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
      this.fetchData()
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
      this.fetchData()
    },
    onUserAgentIdChange(userAgentId) {
      this.selectedUserAgent = userAgentId
      this.$emit('on-user-agent-change', userAgentId)
      this.fetchData()
    },
    displayCreditType(type) {
      switch (`${type}`) {
        case '0':
          return 'ฝาก (+)'
        case '1':
          return 'ถอน (-)'
        case '2':
          return 'ไม่อนุมัติถอนเงิน (+)'
        case '3':
          return 'ฝากตรง (+)'
        case '4':
          return 'ถอนตรง (-)'
        case '5':
          return 'โบนัส (+)'
        case '6':
          return 'แลกค่าแนะนำ (+)'
        case '7':
          return 'แลกแคชแบค (+)'
        case '8':
          return 'โยกกระเป๋าเงิน (+)'
        case '9':
          return 'เดิมพัน (-)'
        case '10':
          return 'ชนะเดิมพัน (+)'
        case '11':
          return 'ชนะโบนัส (+)'
        case '12':
          return 'ยกเลิกเดิมพัน (+)'
        case '13':
          return 'เดิมพันโมฆะ (-)'
        default:
          return `${type}`
      }
    },
    displayCreditTypeColor(type) {
      switch (`${type}`) {
        case '0':
        case '2':
        case '3':
        case '5':
        case '6':
        case '7':
        case '8':
        case '10':
        case '11':
        case '12':
          return 'success'
        case '1':
        case '4':
        case '9':
        case '13':
          return 'danger'
        default:
          return 'light'
      }
    },
  },
}
</script>
